import Flickity from "flickity";
import jQueryBridget from "jquery-bridget";

const Slideshow = {
  init: () => {
    jQueryBridget("flickity", Flickity, $);

    var slideOptions = {
      cellSelector: ".js-slide",
      cellAlign: "center",
      setGallerySize: true,
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      percentPosition: false,
      draggable: true,
      selectedAttraction: 0.075,
      friction: 0.6,
      lazyLoad: true,
    };

    $(".js-slideshow")
      .each(function (i, el) {
        Slideshow.flickity(el, slideOptions);
      })
      .on("mousemove", function (e) {
        var x = Slideshow.checkPosition(e);
        if (x) {
          $(this).removeClass("w-cursor");
        } else {
          $(this).addClass("w-cursor");
        }
      });
  },

  flickity: (element, options) => {
    var carousel = new Flickity(element, options);
    carousel.on("staticClick", function (event, pointer) {
      var x = Slideshow.checkPosition(pointer);
      if (x) {
        carousel.next("true");
      } else {
        carousel.previous("true");
      }
    });

    carousel.on("change", function (index) {
      $(element)
        .parent()
        .find(".js-slideshowCounter")
        .text(index + 1);
    });

    $(element)
      .parent()
      .find(".js-slideshowPrev")
      .on("click", function () {
        carousel.previous("true");
      });

    $(element)
      .parent()
      .find(".js-slideshowNext")
      .on("click", function () {
        carousel.next("true");
      });
  },

  checkPosition: (pointer) => {
    var windowWidth = $(".js-slideshow").outerWidth();
    var percentage = pointer.pageX / windowWidth;
    var x = Math.round(percentage);
    return x;
  },
};

export default Slideshow;
