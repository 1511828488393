const Shop = {
  init: () => {
    $(".js-shopItem").each(function (i, el) {
      var button = $(el).find(".js-shopSubmit");
      var id = $(button).attr("id");
      paypal
        .Buttons({
          style: {
            color: "black",
            height: 40,
          },

          createOrder: function (data, actions) {
            var quantity = $(el).find(".js-shopInput").val();
            var href = window.location.href + ".json?q=" + id + "&quantity=" + quantity;
            return fetch(href, {
              method: "post",
              headers: {
                "content-type": "application/json",
              },
            })
              .then(function (res) {
                return res.json();
              })
              .then(function (data) {
                return data.result.id;
              });
          },
          onApprove: function (data, actions) {
            return actions.order.capture().then(function (details) {
              alert("Transaction completed by " + details.payer.name.given_name);
            });
          },
        })
        .render("#" + id);
    });
  },
};

export default Shop;
