const Archive = {
  init: () => {
    $(".js-archiveTitle").on("click", function () {
      $(this).next(".js-archiveList").toggleClass("active").slideToggle(300);
      $(this).toggleClass("inactive");
    });
  },
};

export default Archive;
