import L from "leaflet";

const Maps = {
  init: () => {
    Maps.access = {
      maxZoom: 18,
      id: "desertx/cko9r5n3v6c7818pf4cy98mnk",
      tileSize: 512,
      zoomOffset: -1,
      accessToken: "pk.eyJ1IjoiZGVzZXJ0eCIsImEiOiJja285cjFhcWcyenBlMm9tdmd5aXJpcmRlIn0.S7vIMMc8ndVGBDJeooP8mQ",
    };

    Maps.icon = L.divIcon({
      html:
        '<svg viewBox="0 0 350 500" xmlns="http://www.w3.org/2000/svg"><g fill-rule="evenodd"><path d="m179.06 497.73c48.94-81.34 97.23-163.33 147.39-243.89 68.04-109.26-20.2-253.84-151.45-253.84s-219.49 144.58-151.45 253.84c48.72 81.54 98.11 162.69 147.36 243.91a4.8 4.8 0 0 0 8.15 0z" fill="#e7217f"/><path d="m175 86.81c47 0 85.05 37.13 85.05 82.93s-38.05 82.93-85.05 82.93-85.06-37.13-85.06-82.93 38.06-82.93 85.06-82.93z" fill="#fff"/></g></svg>',
      iconSize: [21, 30],
      iconAnchor: [10, 30],
    });

    Maps.popup = {
      closeButton: false,
      className: "map__popup",
    };

    if ($(".js-map").length > 0) Maps.showMap();
  },

  showMap: () => {
    var lastClick = 0;

    $(".js-map").each(function (i, el) {
      let markerArray = [];
      const length = $(el).find(".js-mapItem").length;
      $(el)
        .find(".js-mapItem")
        .each(function (index, element) {
          const itemLat = parseFloat($(element).data("latitude"));
          const itemLong = parseFloat($(element).data("longitude"));
          const marker = L.marker([itemLat, itemLong], {
            icon: Maps.icon,
          });

          if (length > 1) {
            const popup = L.popup(Maps.popup).setContent($(element).html());
            marker.bindPopup(popup).openPopup();
          } else {
            marker.on("click", function (e) {
              if (lastClick <= Date.now() - 100) {
                var lat = e.latlng.lat;
                var lng = e.latlng.lng;
                var url = `https://www.google.com/maps/place/${lat},${lng}`;
                window.open(url, "_blank");
                lastClick = Date.now();
              }
            });
          }

          markerArray.push(marker);
        });

      const map = L.map($(el)[0], { scrollWheelZoom: false });
      const groupOfMarkers = L.featureGroup(markerArray).addTo(map);
      map.fitBounds(groupOfMarkers.getBounds(), { maxZoom: 13 });
      L.tileLayer("https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}", Maps.access).addTo(map);
    });
  },
};

export default Maps;
