const Calendar = {
  init: () => {
    Calendar.initPosition();

    $(".js-calendarPrev").on("click", function () {
      Calendar.scrollCalendar("prev");
    });

    $(".js-calendarNext").on("click", function () {
      Calendar.scrollCalendar("next");
    });

    $(".js-calendarDay").on("click", function () {
      if ($(this).hasClass("selected") || $(this).hasClass("js-activeDay")) {
        Calendar.loadDates(false);
      } else {
        Calendar.loadDates(this);
      }
    });

    $(".js-calendarMonthTitle").on("click", function () {
      var widthDay = Calendar.getWidthDay();
      var firstDayOfMonth = $(this).next().find(".js-calendarDay").first();
      var offset = $(firstDayOfMonth).offset().left - $(".js-calendarContainer").offset().left + $(".js-calendarContainer").scrollLeft() - widthDay;
      $(".js-calendarContainer").animate({ scrollLeft: offset }, 400);
    });
  },

  initPosition: () => {
    if ($(".js-calendarContainer").length > 0) {
      var widthDay = Calendar.getWidthDay();
      var offset = $(".js-activeDay").offset().left - $(".js-calendarContainer").offset().left - widthDay;
      $(".js-calendarContainer").scrollLeft(offset);
    }
  },

  scrollCalendar: (type) => {
    var widthDay = Calendar.getWidthDay();
    var days = $(".js-calendarDay").filter(function (i) {
      var offset = $(this).offset().left - $(".js-calendarContainer").offset().left;
      if (type == "prev") return offset < widthDay;
      if (type == "next") return offset > widthDay;
    });

    var events = $(days).filter(".js-dayWithEvent, .js-activeDay");
    if ($(events).length > 0) {
      var lastEvent = type == "prev" ? $(events).last() : $(events).first();
      var offset = $(lastEvent).offset().left - $(".js-calendarContainer").offset().left + $(".js-calendarContainer").scrollLeft() - widthDay;
    } else {
      var lastMonth = $(".js-calendarMonth").last();
      var offset = type == "prev" ? 0 : $(lastMonth).offset().left + $(lastMonth).outerWidth() + $(".js-calendarContainer").scrollLeft() - widthDay;
    }

    $(".js-calendarContainer").animate({ scrollLeft: offset }, 400);
  },

  loadDates: (sourceDay) => {
    var day = sourceDay ? sourceDay : $(".js-activeDay");
    $(".js-calendarDay").removeClass("selected");
    $(day).addClass("selected");
    var widthDay = Calendar.getWidthDay();
    var offset = $(day).offset().left - $(".js-calendarContainer").offset().left + $(".js-calendarContainer").scrollLeft() - widthDay;
    $(".js-calendarContainer").animate({ scrollLeft: offset }, 400);
    $(".js-calendarDay").removeClass("active");
    $(day).addClass("active");

    $(".js-agendaGrid")
      .animate({ opacity: 0 }, 400)
      .promise()
      .done(function () {
        var height = $(".js-agendaGridInner").outerHeight();
        $(".js-agendaGrid").css({ height: height }).empty();

        var date = $(day).data("day");
        var href = sourceDay ? window.location.href + "?date=" + date : window.location.href;
        var grid = null;

        $.get(href, function (data) {
          grid = $(data).find(".js-agendaGrid").html();
        }).done(function () {
          $(".js-agendaGrid").html(grid);
          setTimeout(() => {
            var newHeight = $(".js-agendaGridInner").outerHeight();
            $(".js-agendaGrid")
              .animate({ opacity: 1, height: newHeight }, 400)
              .promise()
              .done(function () {
                $(".js-agendaGrid").removeAttr("style");
              });
          }, 100);
        });
      });
  },

  getWidthDay: () => {
    if (window.matchMedia("(min-width: 800px)").matches) {
      return $(".js-calendarDay").first().outerWidth() * 6;
    } else {
      return $(".js-calendarDay").first().outerWidth() * 2;
    }
  },
};

export default Calendar;
