const Menu = {
  init: () => {
    $(".js-headerItem").on("click", function () {
      if ($(this).hasClass("active")) {
        Menu.closeMenu();
      } else {
        var index = $(".js-headerItem").index(this);
        if ($(".js-menu").hasClass("active")) {
          Menu.changeMenu(this, index);
        } else {
          Menu.openMenu(this, index);
        }
      }
    });

    $(".js-headerArrow").on("click", function () {
      if ($(".js-menu").hasClass("active")) {
        Menu.closeMenu();
      } else {
        Menu.openMenu(false, 0);
      }
    });

    $(".js-menuItem").on("click", function () {
      if (!$(this).hasClass("active")) {
        var index = $(".js-menuItem").index(this);
        Menu.openSubMenu(this, index);
      }
    });
  },

  closeMenu: () => {
    $(".js-menuItem").removeClass("active");
    $(".js-headerItem").removeClass("active");
    $(".js-menu").removeClass("active");
    $(".js-header").removeClass("opened--menu");
    $(".js-headerArrow").removeClass("active");
    $(".js-menuSection").removeClass("active");
  },

  openMenu: (el = false, index) => {
    if (el) $(el).addClass("active");
    if (index == 0) $(".js-headerItem").eq(0).addClass("active");
    $(".js-header").removeClass("minified").addClass("opened--menu");
    $(".js-menu").addClass("active");
    $(".js-headerArrow").addClass("active");
    Menu.openSection(el, index);
  },

  changeMenu: (el, index) => {
    $(".js-menuItem").removeClass("active");
    $(".js-menuSection").removeClass("active");
    $(".js-headerItem").not(el).removeClass("active");
    $(el).addClass("active");
    Menu.openSection(el, index);
  },

  openSection: (el, index) => {
    $(".js-menuColumn").eq(0).find(".js-menuSection").eq(index).addClass("active");

    var secondCol = $(el).hasClass("js-headerItemGive") ? 1 : 0;
    $(".js-menuColumn").eq(1).find(".js-menuSection").eq(secondCol).addClass("active");
  },

  openSubMenu: (el, index) => {
    $(el).addClass("active");
    $(".js-menuColumn")
      .eq(1)
      .find(".js-menuSection")
      .removeClass("active")
      .eq(2)
      .addClass("active")
      .find(".js-menuSub")
      .removeClass("active")
      .eq(index)
      .addClass("active");
  },
};

export default Menu;
