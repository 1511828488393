import "./_lib/modernizr";
import "../scss/application.scss";
import lazySizes from "lazysizes";
import jquery from "jquery";

window.$ = window.jQuery = jquery;

import Website from "./_modules/website";
import Grid from "./_modules/grid";
import Header from "./_modules/header";
import Maps from "./_modules/maps";
import Podcast from "./_modules/podcast";
import Slideshow from "./_modules/slideshow";
import Menu from "./_modules/menu";
import Archive from "./_modules/archive";
import Calendar from "./_modules/calendar";
import Give from "./_modules/give";
import Form from "./_modules/form";
import Shop from "./_modules/shop";

$(function () {
  Website.init();
  Give.init();
  Header.init();
  Maps.init();
  Podcast.init();
  Slideshow.init();
  Menu.init();
  Grid.init();
  Archive.init();
  Calendar.init();
  Form.init();
  Shop.init();
});
