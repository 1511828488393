const Form = {
  init: () => {
    $(".js-inputFile").each(function () {
      var label = $(this).next("label");
      var labelValue = $(this).html();

      $(this).on("change", function (e) {
        var fileName = "";
        if (e.target.value) fileName = e.target.value.split("\\").pop();
        var labelVal = fileName ? fileName : labelValue;
        label.html(labelVal);
      });
    });
  },
};

export default Form;
