const Podcast = {
  init: () => {
    $(".js-podcastButton").on("click", function () {
      var container = $(this).parents(".js-podcast");
      var audio = $(container).find(".js-podcastAudio")[0];

      if ($(this).hasClass("pause")) {
        audio.pause();
        $(this).removeClass("pause");
      } else {
        $(".js-podcastAudio")
          .not(audio)
          .each(function (i, el) {
            var audio = $(el)[0];
            audio.pause();
            $(".js-podcastButton").removeClass("pause");
          });
        audio.play();
        $(this).addClass("pause");
      }
    });
  },
};

export default Podcast;
