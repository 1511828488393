import Isotope from "isotope-layout";
import imagesLoaded from "imagesloaded";
import jQueryBridget from "jquery-bridget";

const Grid = {
  init: () => {
    Grid.filters = new Array();

    jQueryBridget("isotope", Isotope, $);
    jQueryBridget("imagesLoaded", imagesLoaded, $);
    Grid.container = $(".js-grid").isotope({
      itemSelector: ".js-gridItem",
      percentPosition: true,
      masonry: {
        columnWidth: ".js-gridSizer",
      },
    });

    Grid.container.imagesLoaded(() => {
      Grid.container.isotope("layout");
    });

    $(".js-archiveFilter")
      .not(".inactive")
      .on("click", function () {
        var value = $(this).data("filter");
        var group = $(this).parents(".js-archiveList");
        var groupIndex = $(".js-archiveList").index(group);
        var filterGroup = Grid.filters[groupIndex];
        if (!filterGroup) filterGroup = Grid.filters[groupIndex] = [];

        if (!$(this).hasClass("active")) {
          filterGroup.push(value);
          $(this).addClass("active");
        } else {
          var index = filterGroup.indexOf(value);
          filterGroup.splice(index, 1);
          $(this).removeClass("active");
        }

        var comboFilter = Grid.getComboFilter();
        $(".js-grid").isotope({ filter: comboFilter });
      });
  },

  getComboFilter: () => {
    var combo = [];
    for (var prop in Grid.filters) {
      var group = Grid.filters[prop];
      if (!group.length) {
        continue;
      }
      if (!combo.length) {
        combo = group.slice(0);
        continue;
      }
      var nextCombo = [];
      for (var i = 0; i < combo.length; i++) {
        for (var j = 0; j < group.length; j++) {
          var item = combo[i] + group[j];
          nextCombo.push(item);
        }
      }
      combo = nextCombo;
    }
    var comboFilter = combo.join(", ");
    return comboFilter;
  },
};

export default Grid;
