const Header = {
  init: () => {
    Header.lastScrollTop = 0;
    Header.delta = 5;
    Header.headerHeight = $(".js-header").outerHeight();

    var didScroll;

    $(window).on("scroll", function (e) {
      didScroll = true;
    });

    setInterval(function () {
      if (didScroll) {
        Header.hasScrolled();
        didScroll = false;
      }
    }, 250);
  },

  hasScrolled: () => {
    var scrollTop = $(window).scrollTop();
    if (Math.abs(Header.lastScrollTop - scrollTop) <= Header.delta) return;
    if (scrollTop > Header.lastScrollTop && scrollTop > Header.headerHeight) {
      if (!$(".js-menu").hasClass("active"))
        $(".js-header").addClass("minified");
    } else {
      if (scrollTop + $(window).height() < $(document).height()) {
        if (!$(".js-menu").hasClass("active"))
          $(".js-header").removeClass("minified");
      }
    }
    Header.lastScrollTop = scrollTop;
  },
};

export default Header;
